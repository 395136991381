import React from "react"
import NotFoundMessage from "../components/Messages/NotFoundMessage"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <NotFoundMessage />
  </>
)

export default NotFoundPage
