import React from "react"
import { Link } from "gatsby"
import "./Message.scss"

export default function NotFoundMessage() {
  return (
    <div className="container">
      <div className="row text-center text-light p-5 justify-content-center">
        <h2 className="text-light col-12">404</h2>
        <p className="text-muted col-12">Page not found</p>
        <Link className="btn btn-primary text-light text-center mt-3" to="/">
          Back to Home
        </Link>
      </div>
    </div>
  )
}
